import { useEffect, useMemo, useState } from 'react'
import { HeadingNode } from '@lexical/rich-text'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'

import { RemoveHTMLTagsInString } from 'helpers'

import {
  AutoFocusPlugin,
  OnChangePlugin,
  RichTextPlugin,
  ToolbarPlugin,
} from './Plugins'

export const RichEditor = ({
  onFocus,
  onBlur,
  showToolbar,
  disabled,
  handleOnChange,
  focus,
  value,
  placeholder,
  testId = '',
}) => {
  const [questionTitle, setQuestionTitle] = useState(value)
  const [isFocused, setIsFocused] = useState(false)
  const [firstRender, setFirstRender] = useState(true)

  const valueIsEmpty = useMemo(() => {
    return RemoveHTMLTagsInString(value).length === 0
  }, [value])

  const handleFocus = () => {
    onFocus()
    setIsFocused(true)
  }

  const handleBlur = () => {
    onBlur()
    setIsFocused(false)
  }

  const onError = () => {}

  const initConfig = {
    nodes: [HeadingNode],
    theme: {
      text: {
        bold: 'textBold',
        italic: 'textItalic',
      },
    },
    onError,
  }

  useEffect(() => {
    setQuestionTitle(value)
  }, [value])

  useEffect(() => {
    setTimeout(() => {
      setFirstRender(false)
    }, 0)
  }, [])

  return (
    <div onFocus={handleFocus} onBlur={handleBlur}>
      <LexicalComposer initialConfig={initConfig}>
        <OnChangePlugin
          disabled={disabled}
          onChange={handleOnChange}
          value={questionTitle}
          isFocused={isFocused}
          firstRender={firstRender}
        />
        <HistoryPlugin />
        <ClearEditorPlugin />
        <AutoFocusPlugin focus={focus} />
        <RichTextPlugin
          testId={testId}
          placeholder={valueIsEmpty && placeholder}
        />
        <ToolbarPlugin value={questionTitle} showToolbar={showToolbar} />
      </LexicalComposer>
    </div>
  )
}
