import { useAppState, useFocused } from 'hooks'
import classNames from 'classnames'
import { STATES, SURVEY_PANELS } from 'helpers'
import { SideBar } from 'components/SideBar'
import { QuestionSettings } from 'components/QuestionSettings/QuestionSettings'
import { WelcomeSettings } from 'components/WelcomeSettings/WelcomeSettings'
import { QuestionTypeInfo } from 'components/QuestionTypes'
import { EndScreenSettings } from 'components/EndScreenSettings/EndScreenSettings'

export const RightSideBar = ({ surveyId }) => {
  const [isAddingQuestionOrGroup] = useAppState(
    STATES.IS_ADDING_QUESTION_OR_GROUP,
    false
  )
  const [currentOpenPanel = {}] = useAppState(STATES.CURRENT_OPEN_PANEL)
  const { focused } = useFocused()

  if (
    isAddingQuestionOrGroup ||
    (currentOpenPanel.panel &&
      currentOpenPanel.panel !== SURVEY_PANELS.structure.panel)
  ) {
    return <></>
  }

  return (
    <SideBar
      className={classNames('right-side-bar', {
        'active-side-bar': focused,
        'bg-white': focused,
      })}
      q
      visible={focused}
    >
      {focused && <QuestionSettings surveyId={surveyId} />}
      {focused?.info?.type === QuestionTypeInfo.WELCOME_SCREEN.type && (
        <WelcomeSettings surveyId={surveyId} />
      )}
      {focused?.info?.type === QuestionTypeInfo.END_SCREEN.type && (
        <EndScreenSettings surveyId={surveyId} />
      )}
    </SideBar>
  )
}
