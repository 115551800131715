import { ExclamationMark, XIcon } from 'components/icons'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import React from 'react'

export const SwalAlert = withReactContent(Swal)

export const confirmAlert = ({
  title = 'Are you sure?',
  text = "You won't be able to revert this!",
  showCancelButton = true,
  confirmButtonText = 'Yes, delete it',
  icon,
  html = '',
  width = 400,
  confirmButtonClass = '',
  containerClass = '',
  showCloseButton = true,
  closeButtonClass,
}) => {
  return SwalAlert.fire({
    showCloseButton,
    title,
    text,
    icon: icon,
    showCancelButton,
    confirmButtonText,
    html: html,
    width: width,
    customClass: {
      confirmButton: `${confirmButtonClass}`,
      container: `${containerClass}`,
      closeButton: `${closeButtonClass}`,
    },
  })
}

export const htmlPopup = ({
  title,
  icon,
  html,
  showCloseButton,
  showCancelButton,
  showConfirmButton,
  focusConfirm,
  confirmButtonText,
  confirmButtonAriaLabel,
  cancelButtonText,
  cancelButtonAriaLabel,
  containerClass,
  closeButtonClass,
  width,
}) => {
  return SwalAlert.fire({
    title,
    icon,
    html,
    showCloseButton,
    showCancelButton,
    focusConfirm,
    confirmButtonText,
    confirmButtonAriaLabel,
    cancelButtonText,
    cancelButtonAriaLabel,
    showConfirmButton,
    customClass: {
      closeButton: `${closeButtonClass}`,
      container: `${containerClass}`,
    },
    width,
  })
}

export const errorToast = (title, position = 'bottom-right') => {
  return Toast({
    message: title,
    position: position,
    leftIcon: <ExclamationMark />,
    rightIcon: <XIcon />,
    duration: 50000,
  })
}

export const Toast = ({
  message,
  position = 'bottom-right',
  className = 'error-toast',
  leftIcon = '',
  rightIcon = <XIcon />,
  onRightIconClick = () => {},
  onLeftIconClick = () => {},
  duration = 5000,
  id,
}) => {
  toast.custom(
    (t) => (
      <div
        className={`${className} ${
          t.visible ? 'animate-enter' : 'animate-leave'
        }`}
      >
        <div onClick={onLeftIconClick}> {leftIcon}</div>
        <div>{message}</div>
        <div
          className="mouse-pointer"
          onClick={() => {
            onRightIconClick()
            toast.remove(t.id)
          }}
        >
          {rightIcon}
        </div>
      </div>
    ),
    {
      position,
      duration,
      id: id || message,
    }
  )
}
