import { SETTINGS_TITLES } from 'helpers'

import {
  STATISTICS_ATTRIBUTES,
  LOGIC_ATTRIBUTES,
  DISPLAY_ATTRIBUTES,
  GENERAL_ATTRIBUTES,
} from '../attributes'

const simpleSettings = [
  GENERAL_ATTRIBUTES.QUESTION_CODE,
  GENERAL_ATTRIBUTES.QUESTION_TYPE,
  GENERAL_ATTRIBUTES.MANDATORY,
  DISPLAY_ATTRIBUTES.IMAGE_SETTINGS,
  GENERAL_ATTRIBUTES.LOGIC,
  STATISTICS_ATTRIBUTES.SHOW_IN_STATISTICS,
  // todo: why we don't need this?
  // GENERAL_ATTRIBUTES.OTHER,
]

const generalSettings = [
  GENERAL_ATTRIBUTES.QUESTION_CODE,
  GENERAL_ATTRIBUTES.QUESTION_TYPE,
  GENERAL_ATTRIBUTES.MANDATORY,
  GENERAL_ATTRIBUTES.ENCRYPTED,
  GENERAL_ATTRIBUTES.SAVE_AS_DEFAULT,
]

const displaySettings = [
  DISPLAY_ATTRIBUTES.IMAGE_SETTINGS,
  DISPLAY_ATTRIBUTES.LABEL_FOR_OTHER_OPTIONS,
  DISPLAY_ATTRIBUTES.POSITION_FOR_OTHER_OPTION,
  DISPLAY_ATTRIBUTES.ANSWER_OPTIONS_ORDER,
  DISPLAY_ATTRIBUTES.HIDE_TIP,
  DISPLAY_ATTRIBUTES.ALWAYS_HIDE_THIS_QUESTION,
  DISPLAY_ATTRIBUTES.CSS_CLASSES,
  DISPLAY_ATTRIBUTES.CONDITION_HELP_FOR_PRINTABLE_SURVEY,
]

const logicSettings = [LOGIC_ATTRIBUTES.RANDOMIZATION_GROUP_NAME]

// todo: why is it empty?
const otherSettings = []

const inputSettings = []

const statisticsSettings = [
  STATISTICS_ATTRIBUTES.SHOW_IN_STATISTICS,
  // todo: why we don't need this?
  STATISTICS_ATTRIBUTES.SHOW_IN_PUBLIC_STATISTICS,
]

// todo: why is it empty?
const timerSettings = []

const themeOptionsSettings = []

const fileMetaDataSettings = []

const locationSettings = []

const sliderAttributes = []

export const LIST_RADIO_WITH_COMMENT_SETTINGS = [
  { title: SETTINGS_TITLES.SIMPLE, attributes: simpleSettings },
  { title: SETTINGS_TITLES.GENERAL, attributes: generalSettings },
  { title: SETTINGS_TITLES.DISPLAY, attributes: displaySettings },
  { title: SETTINGS_TITLES.LOGIC, attributes: logicSettings },
  { title: SETTINGS_TITLES.OTHER, attributes: otherSettings },
  { title: SETTINGS_TITLES.INPUT, attributes: inputSettings },
  { title: SETTINGS_TITLES.STATISTICS, attributes: statisticsSettings },
  { title: SETTINGS_TITLES.TIMER, attributes: timerSettings },
  {
    title: SETTINGS_TITLES.THEME_OPTIONS,
    attributes: themeOptionsSettings,
  },
  {
    title: SETTINGS_TITLES.FILE_META_DATA,
    attributes: fileMetaDataSettings,
  },
  { title: SETTINGS_TITLES.LOCATION, attributes: locationSettings },
  {
    title: SETTINGS_TITLES.SLIDER,
    attributes: sliderAttributes,
  },
]
