import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import { PlusLg } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import { useState } from 'react'

import { Entities } from 'helpers'

import { RankingAdvancedQuestionAnswersPlaceholder } from './RankingAdvancedQuestionAnswersPlaceholder'
import { RankingAdvancedQuestionAnswers } from './RankingAdvancedQuestionAnswers'

const ANSWERS_DROPPALE_ID = 'ranking-advanced-answers'

export const RankingAdvancedQuestion = ({
  question: { answers = [], qid } = {},
  isFocused,
  handleChildL10nsUpdate,
  handleChildAdd,
  handleChildDelete,
  handleOnChildDragEnd,
}) => {
  const [answersHeight, setAnswersHeight] = useState([])

  const handleOnDragEnd = (dropResult) => {
    handleOnChildDragEnd(dropResult, answers, Entities.answer)
  }

  const handleAnswerUpdate = (value, index) => {
    handleChildL10nsUpdate(value, index, answers, Entities.answer)
  }

  const handleRemoveAnswer = (answer) => {
    handleChildDelete(answer.aid, answers, Entities.answer)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div
        data-testid="ranking-advanced-question"
        className={'ranking-advanced-question d-flex flex-row'}
      >
        <Droppable droppableId={ANSWERS_DROPPALE_ID}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <RankingAdvancedQuestionAnswers
                droppableProvider={provided}
                isFocused={isFocused}
                handleAnswerUpdate={handleAnswerUpdate}
                handleRemoveAnswer={handleRemoveAnswer}
                handleOnDragEnd={handleOnDragEnd}
                answers={answers}
                qid={qid}
                setAnswersHeight={setAnswersHeight}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {!isFocused && (
          <RankingAdvancedQuestionAnswersPlaceholder
            isFocused={isFocused}
            answers={answers}
            answersHeight={answersHeight}
          />
        )}
      </div>
      <div>
        <Button
          onClick={() => handleChildAdd(answers, Entities.answer)}
          variant={'outline'}
          className={classNames('text-primary add-choice-button px-0 mt-2', {
            'd-none disabled': !isFocused,
          })}
          data-testid="single-choice-add-answer-button"
        >
          <PlusLg /> Add answer option
        </Button>
      </div>
    </DragDropContext>
  )
}
