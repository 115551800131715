import { ToggleButtons, Select } from 'components/UIComponents'

export const STATISTICS_ATTRIBUTES = {
  SHOW_IN_STATISTICS: {
    component: Select,
    attributePath: 'attributes.statistics_graphtype',
    props: {
      labelText: 'Show in statistics',
      dataTestId: 'display-chart',
      options: [
        {
          label: `Don't show`,
          value: '-1',
        },
        {
          label: 'Bar Chart',
          value: '0',
        },
        {
          label: 'Pie Chart',
          value: '1',
        },
        {
          label: 'Radar',
          value: '2',
        },
        {
          label: 'Line',
          value: '3',
        },
        {
          label: 'Polar Area',
          value: '4',
        },
      ],
      value: '0',
    },
  },
  SHOW_IN_PUBLIC_STATISTICS: {
    component: ToggleButtons,
    attributePath: 'attributes.public_statistics',
    props: {
      labelText: 'Show In Public Statistics',
      id: 'show-in-pubic-statistics',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
}
