import classNames from 'classnames'
import * as Sentry from '@sentry/react'

import {
  FivePointChoiceQuestion,
  TextQuestion,
  RatingQuestion,
  QuestionTypeInfo,
  MultipleChoice,
  SingleChoice,
  FileUpload,
  RankingQuestion,
  RankingAdvancedQuestion,
  Equation,
  DateTime,
  GenderQuestion,
  YesNoQuestion,
  ArrayQuestion,
} from '../../QuestionTypes'
import { useAppState, useBuffer } from 'hooks'
import {
  Entities,
  STATES,
  createBufferOperation,
  getAnswerExample,
  getQuestionExample,
} from 'helpers'

const questionComponents = {
  [QuestionTypeInfo.SINGLE_CHOICE_LIST_RADIO.theme]: SingleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_LIST_RADIO_WITH_COMMENT.theme]: SingleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_DROPDOWN.theme]: SingleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_BUTTONS.theme]: SingleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_IMAGE_SELECT.theme]: SingleChoice,
  [QuestionTypeInfo.MULTIPLE_CHOICE.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_CHOICE_WITH_COMMENTS.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_CHOICE_BUTTONS.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_CHOICE_IMAGE_SELECT.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_SHORT_TEXTS.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_NUMERICAL_INPUTS.theme]: MultipleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_FIVE_POINT_CHOICE.theme]:
    FivePointChoiceQuestion,
  [QuestionTypeInfo.SHORT_TEXT.theme]: TextQuestion,
  [QuestionTypeInfo.BROWSER_DETECTION.theme]: TextQuestion,
  [QuestionTypeInfo.LONG_TEXT.theme]: TextQuestion,
  [QuestionTypeInfo.ARRAY.theme]: ArrayQuestion,
  [QuestionTypeInfo.ARRAY_NUMBERS.theme]: ArrayQuestion,
  [QuestionTypeInfo.ARRAY_TEXT.theme]: ArrayQuestion,
  [QuestionTypeInfo.ARRAY_COLUMN.theme]: ArrayQuestion,
  [QuestionTypeInfo.ARRAY_DUAL_SCALE.theme]: ArrayQuestion,
  [QuestionTypeInfo.RATING.theme]: RatingQuestion,
  [QuestionTypeInfo.FILE_UPLOAD.theme]: FileUpload,
  [QuestionTypeInfo.RANKING.theme]: RankingQuestion,
  [QuestionTypeInfo.RANKING_ADVANCED.theme]: RankingAdvancedQuestion,
  [QuestionTypeInfo.EQUATION.theme]: Equation,
  [QuestionTypeInfo.DATE_TIME.theme]: DateTime,
  [QuestionTypeInfo.GENDER.theme]: GenderQuestion,
  [QuestionTypeInfo.YES_NO.theme]: YesNoQuestion,
}

export const QuestionBody = ({
  question,
  handleUpdate,
  language,
  isFocused,
  isHovered,
  isQuestionDisabled,
  surveySettings,
}) => {
  const { addToBuffer } = useBuffer()
  const QuestionComponent = questionComponents[question.questionThemeName]
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const isFocusedWithPermission = isFocused && hasSurveyUpdatePermission

  if (!QuestionComponent) {
    return <></>
  }

  const handleChildAdd = (childArray = [], entityType) => {
    const childKey = entityType === Entities.answer ? 'answers' : 'subquestions'
    const updatedChildren = [...childArray]

    const newChild =
      entityType === Entities.answer
        ? getAnswerExample({
            qid: question.qid,
            sortOrder: updatedChildren.length + 1,
            language,
          })
        : getQuestionExample({
            gid: question.gid,
            language: language,
            sortOrder: updatedChildren.length + 1,
            scaleId: question.scaleId,
            parentQid: question.qid,
          })

    updatedChildren.push(newChild)

    const operation =
      entityType === Entities.answer
        ? createBufferOperation(question.qid)
            .answer()
            .update([...updatedChildren])
        : createBufferOperation(question.qid)
            .subquestion()
            .update([...updatedChildren])

    addToBuffer(operation)
    handleUpdate({ [childKey]: updatedChildren })
  }

  const handleChildDelete = (childId, childArray = [], entityType) => {
    const childKey = entityType === Entities.answer ? 'answers' : 'subquestions'
    const idKey = entityType === Entities.answer ? 'aid' : 'qid'

    const updatedChildren = (childArray || []).filter(
      (child) => child[idKey] !== childId
    )

    const operation =
      entityType === Entities.answer
        ? createBufferOperation(question.qid)
            .answer()
            .update([...updatedChildren])
        : createBufferOperation(question.qid)
            .subquestion()
            .update([...updatedChildren])

    addToBuffer(operation)
    handleUpdate({ [childKey]: updatedChildren })
  }

  const handleOnChildDragEnd = (
    dropResult,
    childArray = [],
    entityType,
    offsetIndex = 0
  ) => {
    // dropped outside the list
    if (!dropResult.destination) {
      return
    }

    const childKey = entityType === Entities.answer ? 'answers' : 'subquestions'
    let updatedChildren = [...childArray]

    const startIndex = dropResult.source.index + offsetIndex
    const endIndex = dropResult.destination.index + offsetIndex

    const [removed] = updatedChildren.splice(startIndex, 1)
    updatedChildren.splice(endIndex, 0, removed)

    updatedChildren = updatedChildren?.map((child, index) => {
      return {
        ...child,
        sortOrder: index + 1,
      }
    })

    const operation =
      entityType === Entities.answer
        ? createBufferOperation(question.qid)
            .answer()
            .update([...updatedChildren])
        : createBufferOperation(question.qid)
            .subquestion()
            .update([...updatedChildren])

    addToBuffer(operation)
    handleUpdate({ [childKey]: updatedChildren })
  }

  const handleChildL10nsUpdate = (
    newValue = '',
    childIndex,
    childArray = [],
    entityType
  ) => {
    const updatedChildren = [...childArray]
    const l10nsKey = entityType === Entities.answer ? 'answer' : 'question'
    const childKey = entityType === Entities.answer ? 'answers' : 'subquestions'

    if (updatedChildren[childIndex] === undefined) {
      Sentry.captureMessage(
        `Error while updating l10n in ${question.questionThemeName} - unable to find item`,
        {
          updatedEntities: updatedChildren,
          updateKey: childKey,
          index: childIndex,
          question,
        }
      )
      return
    }

    const l10ns = updatedChildren[childIndex]['l10ns'] || {}

    updatedChildren[childIndex] = {
      ...updatedChildren[childIndex],
      l10ns: {
        ...l10ns,
        [language]: {
          ...l10ns[language],
          [l10nsKey]: newValue,
          language: language,
        },
      },
    }

    const operation =
      entityType === Entities.answer
        ? createBufferOperation(question.qid)
            .answer()
            .update([...updatedChildren])
        : createBufferOperation(question.qid)
            .subquestion()
            .update([...updatedChildren])

    addToBuffer(operation)
    handleUpdate({ [childKey]: updatedChildren })
  }

  return (
    <div
      className={classNames('question-body', {
        'disabled opacity-50': isQuestionDisabled,
      })}
    >
      <QuestionComponent
        handleUpdate={handleUpdate}
        handleChildL10nsUpdate={handleChildL10nsUpdate}
        handleChildAdd={handleChildAdd}
        handleChildDelete={handleChildDelete}
        handleOnChildDragEnd={handleOnChildDragEnd}
        question={question}
        language={language}
        isFocused={isFocusedWithPermission}
        isHovered={isHovered}
        surveySettings={surveySettings}
      />
    </div>
  )
}
