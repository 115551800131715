import { Entities, STATES } from 'helpers'
import { useAppState } from 'hooks'

import { SingleChoiceAnswers } from './SingleChoiceAnswers'

export const SingleChoice = ({
  question: { answers } = {},
  question,
  handleUpdate = () => {},
  handleChildL10nsUpdate,
  isFocused,
  surveySettings,
  handleChildAdd,
  handleOnChildDragEnd,
  handleChildDelete,
}) => {
  const [surveyLanguage] = useAppState(STATES.SURVEY_LANGUAGE)

  const handleUpdateNoAnswerAttribute = (value) => {
    question.attributes.no_answer = {
      ...question.attributes.no_answer,
      [surveyLanguage]: {
        value: value,
      },
    }

    handleUpdate({ attributes: question.attributes })
  }

  const handleOnDragEnd = (dropResult) => {
    handleOnChildDragEnd(dropResult, answers, Entities.answer)
  }

  const handleRemovingAnswers = (aid) => {
    handleChildDelete(aid, answers, Entities.answer)
  }

  return (
    <SingleChoiceAnswers
      handleChildAdd={() => handleChildAdd(answers, Entities.answer)}
      handleOnDragEnd={handleOnDragEnd}
      handleRemovingAnswers={handleRemovingAnswers}
      handleUpdateAnswer={(value, index) =>
        handleChildL10nsUpdate(value, index, answers, Entities.answer)
      }
      isFocused={isFocused}
      question={question}
      handleUpdateNoAnswerAttribute={handleUpdateNoAnswerAttribute}
      surveyLanguage={surveyLanguage}
      surveySettings={surveySettings}
    />
  )
}
