import React from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { extraErrorDataIntegration } from '@sentry/integrations'

const isEnabled = function () {
  return process.env.REACT_APP_SENTRY_ENABLE === 'true'
}

const init = function () {
  if (!isEnabled()) return
  Sentry.init({
    release: process.env.REACT_APP_RELEASE,
    dist: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enableTracing: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    sendDefaultPii: true,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    beforeSend(event, hint) {
      // Object captured as exception with keys: message
      // https://sentry.zendesk.com/hc/en-us/articles/26649121915291-I-m-getting-Object-captured-as-exception-with-keys
      // https://github.com/getsentry/sentry-react-native/discussions/3320
      if (
        typeof hint.originalException === 'object' &&
        hint.originalException != null &&
        !(hint.originalException instanceof Error)
      ) {
        event.extra = {
          ...event.extra,
          ...hint.originalException,
        }
      }
      return event
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      extraErrorDataIntegration({
        depth: 5,
      }),
    ],
  })
}

const wrapCreateBrowserRouter = function (router) {
  if (!isEnabled()) return router
  return Sentry.wrapCreateBrowserRouter(router)
}

const withProfiler = function (app) {
  if (!isEnabled()) return app
  return Sentry.withProfiler(app)
}

const sentry = {
  isEnabled,
  init,
  wrapCreateBrowserRouter,
  withProfiler,
}

export default sentry
