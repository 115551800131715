import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Bowser from 'bowser'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker as LeafLetMarker,
} from 'react-leaflet'
import L from 'leaflet'

import { ContentEditor } from 'components/UIComponents'
import { getAttributeValue } from 'helpers'

import './TextQuestion.scss'

// Todo: update the placeholder
const GoogleMapsComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 53.61422133647984, lng: 9.972816890552014 }}
    >
      {props.isMarkerShown && (
        <Marker position={{ lat: 53.61422133647984, lng: 9.972816890552014 }} />
      )}
    </GoogleMap>
  ))
)

const LeafletMapComponent = () => {
  const customIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
  })

  return (
    <MapContainer
      center={[53.61422133647984, 9.972816890552014]}
      zoom={8}
      style={{ height: '350px', width: '100%' }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <LeafLetMarker
        icon={customIcon}
        position={[53.61422133647984, 9.972816890552014]}
      >
        <Popup>You are here!</Popup>
      </LeafLetMarker>
    </MapContainer>
  )
}

export const BrowserDetectionTextAnswer = ({ attributes = {} }) => {
  const [browserInfo, setBrowserInfo] = useState(false)
  const locationMapService = getAttributeValue(attributes.location_mapservice)

  useEffect(() => {
    const { browser, os } = Bowser.parse(window.navigator.userAgent)
    let info = ''
    if (attributes?.add_platform_info?.['']?.value === 'yes') {
      info = `${browser.name} (${browser.version}) | ${os.name} (${os.versionName})`
    } else {
      info = `${browser.name} (${browser.version})`
    }

    setBrowserInfo(info)
  }, [attributes?.add_platform_info])

  return (
    <div className={'question-body-content'}>
      {locationMapService === '0' && (
        <div className="d-flex gap-2 align-items-center justify-content-center">
          {attributes.prefix?.value && (
            <ContentEditor disabled={true} value={attributes.prefix?.value} />
          )}
          <Form.Group className="flex-grow-1">
            <Form.Control
              type={'text'}
              placeholder="Enter your answer here."
              data-testid="text-question-answer-input"
              value={browserInfo}
              disabled={true}
            />
          </Form.Group>
          {attributes.suffix && (
            <ContentEditor disabled={true} value={attributes.suffix?.value} />
          )}
        </div>
      )}
      {locationMapService === '1' && (
        <GoogleMapsComponent
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `350px`, width: '100%' }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      )}
      {locationMapService === '100' && <LeafletMapComponent />}
    </div>
  )
}
