import { AlignButtons, Input, ToggleButtons } from 'components/UIComponents'

export const LOCATION_ATTRIBUTES = {
  USE_MAPPING_SERVICE: {
    component: ToggleButtons,
    attributePath: 'attributes.location_mapservice',
    props: {
      id: 'use-mapping-service',
      labelText: 'Use mapping service',
      optionTextClassName: 'text-sm',
      toggleOptions: [
        { name: 'Google Maps', value: '1' },
        { name: 'OpenStreetMap', value: '100' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '100',
    },
  },
  MAP_POSITION: {
    component: AlignButtons,
    attributePath: 'attributes.location_mapposition',
    props: {
      id: 'map-position',
      labelText: 'Map position',
      dataTestId: 'map-position',
      value: 'center',
    },
  },
  IP_AS_DEFAULT_LOCATION: {
    component: ToggleButtons,
    attributePath: 'attributes.location_nodefaultfromip',
    props: {
      id: 'ip-as-default-location',
      labelText: 'IP as default location',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '1',
    },
  },
  SAVE_COUNTRY: {
    component: ToggleButtons,
    attributePath: 'attributes.location_country',
    props: {
      labelText: 'Save country',
      id: 'save-country',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SAVE_STATE: {
    component: ToggleButtons,
    attributePath: 'attributes.location_state',
    props: {
      labelText: 'Save state',
      id: 'save-state',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SAVE_CITY: {
    component: ToggleButtons,
    attributePath: 'attributes.location_city',
    props: {
      id: 'save-city',
      labelText: 'Save city',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },

  SAVE_POSTAL_CODE: {
    component: ToggleButtons,
    attributePath: 'attributes.location_postal',
    props: {
      id: 'save-postal-code',
      labelText: 'Save postal code',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },

  ZOOM_LEVEL: {
    component: Input,
    attributePath: 'attributes.location_mapzoom',
    props: {
      id: 'zoom-level',
      type: 'number',
      labelText: 'Zoom level',
    },
  },
  DEFAULT_POSITION: {
    component: Input,
    attributePath: 'attributes.location_defaultcoordinates',
    props: {
      dataTestId: 'default-position',
      labelText: 'Default position',
    },
  },
}
