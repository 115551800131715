import React, { useMemo, useState } from 'react'
import { Button, OverlayTrigger } from 'react-bootstrap'
import classNames from 'classnames'
import { $getSelection } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useParams } from 'react-router-dom'

import { useAppState, useFocused, useSurvey } from 'hooks'
import { placeholderStandardFields, isPlaceholderInUse, STATES } from 'helpers'
import { getPreviousQuestions } from './getPreviousQuestions'

export const PlaceholderMenu = () => {
  const [editor] = useLexicalComposerContext()
  const [showPlaceholderMenu, setShowPlaceholderMenu] = useState(false)
  const [language] = useAppState(STATES.SURVEY_LANGUAGE, 'en')
  const { surveyId } = useParams()
  const { survey } = useSurvey(surveyId)
  const { groupIndex, questionIndex } = useFocused()

  const getCurrentUpdatedFocusedQuestion = () => {
    const currentGroup = survey?.questionGroups?.[groupIndex]
    return currentGroup?.questions?.[questionIndex] || null
  }

  const handlePlaceholderField = (placeholder) => {
    editor.update(() => {
      const selection = $getSelection()
      selection.insertText(`{${placeholder}}` + ' ')
    })

    setShowPlaceholderMenu(false)
  }

  const renderPlaceholderButton = (
    key,
    value,
    onClickHandler = () => {},
    selected = false,
    customStyle = {}
  ) => (
    <Button
      variant={`${selected ? 'outline-primary' : 'primary'}`}
      key={key}
      className={classNames(
        'btn',
        {
          'btn-outline-primary': selected,
          'btn-primary': !selected,
        },
        `text-truncate text-start d-block fw-normal mr-2 mt-2 mb-2`
      )}
      onClick={onClickHandler}
      style={customStyle}
    >
      {value}
    </Button>
  )

  const generatePlaceholderButton = (title, value) => {
    return renderPlaceholderButton(
      value,
      title,
      () => handlePlaceholderField(value),
      isPlaceholderInUse(language, getCurrentUpdatedFocusedQuestion(), value),
      { width: '280px' }
    )
  }

  const renderPreviousQuestions = useMemo(() => {
    const previousQuestions = getPreviousQuestions(
      survey,
      groupIndex,
      questionIndex,
      language
    )
    const questions = previousQuestions.map(({ title, value }) =>
      generatePlaceholderButton(title, value)
    )
    return { questions }
  }, [survey, groupIndex, questionIndex, language])

  const renderStandardFields = useMemo(() => {
    return Object.keys(placeholderStandardFields).map((fieldKey, index) =>
      renderPlaceholderButton(
        index,
        placeholderStandardFields[fieldKey],
        () =>
          handlePlaceholderField(index > 2 ? fieldKey : 'TOKEN:' + fieldKey),
        isPlaceholderInUse(
          language,
          getCurrentUpdatedFocusedQuestion(),
          fieldKey
        )
      )
    )
  }, [survey, groupIndex, questionIndex])

  const placeholderFieldsMenuContent = (
    <div className="content-editor-placeholder-menu">
      <div>
        <p className="small pl-0 pb-3 text-left text-black">Standard fields</p>
        {renderStandardFields}
      </div>
      {groupIndex > 0 || questionIndex > 0 ? (
        <>
          <hr className="mt-4 mb-4" />
          <div>
            <p className="small pl-0 pb-3 text-left text-black">
              Fields based on previous answers
            </p>
            <div className="placeholder-fields-scrollable">
              {renderPreviousQuestions.questions}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )

  return (
    <OverlayTrigger
      trigger="click"
      overlay={placeholderFieldsMenuContent}
      placement="right-start"
      show={showPlaceholderMenu}
      onToggle={(show) => {
        setShowPlaceholderMenu(show)
      }}
      rootClose
    >
      <Button
        variant=""
        className="toolbar-button d-flex justify-content-center align-items-center"
      >
        {'{}'}
      </Button>
    </OverlayTrigger>
  )
}
