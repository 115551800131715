import React, { useRef, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Form, Button, Image } from 'react-bootstrap'

import NoImageFound from 'assets/images/no-image-found.jpg'
import { UploadIcon, DeleteIcon } from 'components/icons'
import { errorToast } from 'helpers/Alert'
import { FILE_UPLOAD_MAX_SIZE } from 'helpers/constants'

const handleError = (errorMessage) => {
  errorToast(errorMessage)
}

export const DropZone = ({
  fileService,
  onChange = () => {},
  onChangePreview = () => {},
  previewUrlInit = null,
  isValidImg = true,
  labelText = '',
  previewMaxHeight = '300px',
  dataTestId = 'dropzone',
}) => {
  const [showTrashIcon, setShowTrashIcon] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleOnMouseHover = () => setShowTrashIcon(true)
  const handleOnMouseLeave = () => setShowTrashIcon(false)

  useEffect(() => {
    if (previewUrlInit) {
      setPreviewUrl(previewUrlInit)
    }
  }, [])

  const onChangeRef = useRef(onchange)
  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  const onChangePreviewRef = useRef(onChangePreview)
  useEffect(() => {
    onChangePreviewRef.current = onChangePreview
  }, [onChangePreview])

  const onDropAccepted = async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      setIsLoading(true)

      const tempUrl = URL.createObjectURL(file)

      // Use functional update to ensure latest preview is considered
      setPreviewUrl((prevUrl) => {
        if (prevUrl) URL.revokeObjectURL(prevUrl)
        return tempUrl
      })

      try {
        const formData = new FormData()
        formData.append('file', file, file.name)

        const {
          success,
          uploaded: { filePath, previewUrl: uploadedPreviewUrl } = {},
          uploadResultMessage,
        } = await fileService.uploadSurveyImage(formData)

        if (success) {
          setPreviewUrl((prevUrl) => {
            if (prevUrl) URL.revokeObjectURL(prevUrl)
            return uploadedPreviewUrl
          })
          onChangePreviewRef.current(filePath)
          onChangeRef.current(filePath)
        } else {
          // Restore previous preview in case of failure
          setPreviewUrl((prevUrl) => prevUrl)
          handleError(uploadResultMessage)
        }
      } catch (error) {
        setPreviewUrl((prevUrl) => prevUrl) // Restore previous preview
        handleError(
          error.response
            ? `Upload failed with status code: ${error.response.status}`
            : error.message
        )
      } finally {
        setIsLoading(false)
        URL.revokeObjectURL(tempUrl)
      }
    }
  }

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((error) => {
        handleError(fileRejection.file.name + ': ' + error.message)
      })
    })
  }

  const onDelete = () => {
    // Deleting - does not delete the file from the server
    // - we just remove the reference in the related data
    setShowTrashIcon(false)
    setPreviewUrl(null)
    onChangePreview(null)
    onChange('')
  }

  const onError = (error) => {
    handleError(error.message)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onError,
    maxFiles: 1,
    maxSize: FILE_UPLOAD_MAX_SIZE,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg'],
      'image/gif': ['.gif'],
    },
  })

  const loadingSpinner = (
    <div
      className={`position-absolute file-loading-btn-wrapper ${
        isLoading ? '' : 'd-none'
      }`}
    >
      <div style={{ width: 24, height: 24 }} className="loader"></div>
    </div>
  )

  const emptyDropzone = (
    <div className="dropzone">
      <UploadIcon className="icon" />
      <p className="label">Drop image here</p>
    </div>
  )

  const preview = (
    <div
      className="border border-3 border-secondary rounded"
      onMouseOver={handleOnMouseHover}
      onMouseLeave={handleOnMouseLeave}
    >
      <div
        className="position-relative"
        style={{
          maxHeight: previewMaxHeight,
          background: 'rgba(0, 0, 0, 0.05)',
        }}
      >
        {loadingSpinner}
        <Image
          src={isValidImg ? previewUrl : NoImageFound}
          alt="Image Select List"
          style={{
            height: previewMaxHeight,
          }}
        />
        <div
          className={`position-absolute image-handle-btn-wrapper ${
            !isLoading && showTrashIcon ? '' : 'd-none'
          }`}
        >
          <Button
            variant="outline-light"
            className="image-handle-btn ms-1"
            size="sm"
            onClick={(event) => {
              onDelete()
              event.stopPropagation()
            }}
          >
            <DeleteIcon className="text-primary fill-current" />
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <div
      style={{ cursor: 'pointer', minwidth: '200px' }}
      data-testid={dataTestId}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {labelText.length ? <Form.Label>{labelText}</Form.Label> : <></>}
      {previewUrl ? preview : emptyDropzone}
    </div>
  )
}
