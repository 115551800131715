import { useEffect, useMemo, useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import { max } from 'lodash'

import { ContentEditor, DragAndDrop } from 'components'
import {
  Entities,
  getAttributeValue,
  L10ns,
  NO_ANSWER_LABEL,
  SCALE_1,
  SCALE_2,
  STATES,
} from 'helpers'
import { useAppState } from 'hooks'
import { DISPLAY_ATTRIBUTES } from 'components/QuestionSettings/attributes'
import { QuestionTypeInfo } from 'components/QuestionTypes/QuestionTypeInfo'

import { ArrayHorizontalTitle } from './ArrayHorizontalTitle'

export const ArrayHorizontalTitles = ({
  question: {
    answers = [],
    subquestions = [],
    attributes = {},
    qid,
    questionThemeName,
  } = {},
  onDragEndCallback,
  highestSubquestionWidth,
  isFocused,
  arrayAnswersWidth,
  setArrayAnswersWidth,
  dragIconSize,
  scaleId,
  highestHeight,
  setHighestHeight = () => {},
  setNumberOfHorizontalEntities,
  setHorizontalInfo = () => {},
  handleUpdateL10ns = () => {},
  removeItem = () => {},
  handleHeaderUpdate = () => {},
  isArrayDualScale = false,
  handleHeaderHeightChange = () => {},
  headersHeight,
  showNoAnswer = false,
}) => {
  const [isReorderingAnswers, setIsReorderingAnswers] = useState(false)
  const [language] = useAppState(STATES.SURVEY_LANGUAGE)
  const answersContainerRef = useRef(null)

  const isArrayByColumn =
    questionThemeName === QuestionTypeInfo.ARRAY_COLUMN.theme
  const isArrayByText = questionThemeName === QuestionTypeInfo.ARRAY_TEXT.theme
  const isArrayByNumbers =
    questionThemeName === QuestionTypeInfo.ARRAY_NUMBERS.theme

  const headerValue = useMemo(() => {
    const attributeName =
      scaleId === SCALE_1
        ? DISPLAY_ATTRIBUTES.SCALE_HEADER_A.attributeName
        : DISPLAY_ATTRIBUTES.SCALE_HEADER_B.attributeName

    return getAttributeValue(attributes[attributeName], language)
  })

  const entitiesInfo = useMemo(() => {
    let info = {}

    if (isArrayByText || isArrayByNumbers) {
      const items = Array.isArray(subquestions)
        ? subquestions.filter(
            (subquestion) => subquestion.scaleId === SCALE_2 || isArrayByColumn
          )
        : []

      info = {
        items,
        itemsKey: 'subquestions',
        idKey: 'qid',
        sortKey: 'sortOrder',
        titleKey: 'question',
        rowName: 'subquestion',
        placeHolder: 'Subquestion',
        scaleId: isArrayByColumn ? undefined : SCALE_2,
        entity: Entities.subquestion,
      }
    } else {
      const items =
        questionThemeName === QuestionTypeInfo.ARRAY_DUAL_SCALE.theme
          ? answers.filter((answer) => answer.scaleId === scaleId)
          : answers

      info = {
        items,
        itemsKey: 'answers',
        idKey: 'aid',
        sortKey: 'sortOrder',
        titleKey: 'answer',
        rowName: 'answer option',
        placeHolder: 'Answer option',
        scaleId: scaleId ? scaleId : SCALE_1,
        entity: Entities.answer,
      }
    }

    setTimeout(() => {
      setNumberOfHorizontalEntities(info.items?.length || 0)
      setHorizontalInfo(info)
    }, 0)

    return info
  }, [
    answers,
    subquestions,
    isArrayByText,
    isArrayByNumbers,
    isArrayByColumn,
    questionThemeName,
  ])

  const handleSizeUpdate = () => {
    let highestHeight = 0
    if (!answersContainerRef.current) {
      return
    }

    answersContainerRef.current
      .querySelectorAll('.array-answer-content-editor')
      .forEach((item, index) => {
        const height = item.offsetHeight
        arrayAnswersWidth[index] = item.clientWidth
        if (height > highestHeight) {
          highestHeight = height
        }
      })

    if (isArrayDualScale) {
      const header = answersContainerRef.current.querySelector(
        '.array-dual-scale-header-input'
      )

      handleHeaderHeightChange(header?.clientHeight, scaleId)
    }

    setArrayAnswersWidth([...arrayAnswersWidth])
    setHighestHeight(highestHeight, scaleId)
  }

  const handleOnDragEnd = (dragResult) => {
    setIsReorderingAnswers(false)

    onDragEndCallback(dragResult, entitiesInfo)

    setTimeout(() => {
      handleSizeUpdate()
    }, 1)
  }

  useEffect(() => {
    const heightObserver = new ResizeObserver(() => {
      handleSizeUpdate()
    })

    if (answersContainerRef.current) {
      answersContainerRef.current
        .querySelectorAll(
          '.array-answer-content-editor, .array-dual-scale-header-input'
        )
        .forEach((item) => {
          heightObserver.observe(item)
        })

      heightObserver.observe(answersContainerRef.current)
    }

    return () => {
      heightObserver.disconnect()
    }
  }, [])

  const getAnswerStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  })

  return (
    <div ref={answersContainerRef}>
      <DragAndDrop
        onDragEnd={handleOnDragEnd}
        onDragStart={() => {
          setIsReorderingAnswers(true)
        }}
        droppableId={'droppable-subquestions-answers'}
        className={classNames('d-flex')}
        direction="horizontal"
      >
        <div
          style={{
            minWidth:
              scaleId === SCALE_1 ? highestSubquestionWidth + dragIconSize : 0,
          }}
        ></div>
        <div style={{ marginTop: '20px' }}>
          {isArrayDualScale && (
            <div
              style={{ minHeight: max(headersHeight) }}
              className="d-flex justify-content-center"
            >
              <ContentEditor
                placeholder={`Header ${scaleId + 1}`}
                value={headerValue}
                update={(value) => handleHeaderUpdate(value, scaleId)}
                style={{ width: 'max-content' }}
                className={'array-dual-scale-header-input'}
              />
            </div>
          )}
          <div className="d-flex position-relative">
            {entitiesInfo?.items?.map((entity, index) => {
              return (
                <Draggable
                  key={`${
                    entity[entitiesInfo.idKey]
                  }-${qid}-${index}-subQuestionAnswerTitle`}
                  draggableId={`${
                    entity[entitiesInfo.idKey]
                  }-${qid}-${index}-subQuestionAnswerTitle`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getAnswerStyle(provided.draggableProps.style)}
                      className={classNames('text-center text-secondary', {
                        'focus-element':
                          snapshot.isDragging || isReorderingAnswers,
                        'bg-grey': !(index % 2) && isArrayByColumn,
                      })}
                    >
                      <ArrayHorizontalTitle
                        dragIconSize={dragIconSize}
                        removeItem={() => removeItem(entitiesInfo, entity)}
                        highestHeight={highestHeight}
                        index={index}
                        isFocused={isFocused}
                        provided={provided}
                        title={L10ns({
                          prop: entitiesInfo.titleKey,
                          language,
                          l10ns: entity.l10ns,
                        })}
                        handleUpdateL10ns={(value, index) =>
                          handleUpdateL10ns(value, entitiesInfo, index)
                        }
                        placeHolder={entitiesInfo.placeHolder}
                        itemsKey={entitiesInfo.itemsKey}
                      />
                    </div>
                  )}
                </Draggable>
              )
            })}
            {showNoAnswer && isArrayDualScale && (
              <div className={classNames('question-body-content')}>
                <ArrayHorizontalTitle
                  dragIconSize={dragIconSize}
                  removeItem={() => {}}
                  highestHeight={highestHeight}
                  index={answers.length}
                  isFocused={isFocused}
                  title={NO_ANSWER_LABEL}
                  handleUpdateL10ns={() => {}}
                  itemsKey={entitiesInfo.itemsKey}
                  isNoAnswer={true}
                />
              </div>
            )}
          </div>
        </div>
      </DragAndDrop>
    </div>
  )
}
