import { RemoveHTMLTagsInString } from '../../helpers'

export const getPreviousQuestions = (
  survey,
  groupIndex,
  questionIndex,
  language
) => {
  const result = []

  if (!Array.isArray(survey.questionGroups)) {
    return result
  }

  survey.questionGroups.forEach((group) => {
    if (!Array.isArray(group.questions)) {
      return
    }

    group.questions.forEach((question) => {
      if (
        !question ||
        !hasPreviousQuestions(group, question, groupIndex, questionIndex)
      ) {
        return
      }

      if ([';', ':'].includes(question.type)) {
        const subquestions = question.subquestions ?? []
        const xAxis = {}

        subquestions.forEach((subq) => {
          if (subq.scaleId === 1) {
            xAxis[subq.title] = getQuestionTitle(subq, language)
          }
        })

        subquestions.forEach((subq) => {
          if (subq.scaleId === 0) {
            Object.entries(xAxis).forEach(([key, value]) => {
              result.push({
                title: `${question.title}: [${RemoveHTMLTagsInString(value)}] [${getQuestionTitle(subq, language)}] ${getQuestionTitle(question, language)}`,
                value: `${question.title}_${subq.title}_${key}.shown`,
              })
            })
          }
        })
      } else if (question.type === '1') {
        const subquestions = question.subquestions ?? []
        subquestions.forEach((subq) => {
          const baseTitle = `${question.title}: [${getQuestionTitle(subq, language)}] ${getQuestionTitle(question, language)}`
          result.push(
            {
              title: baseTitle,
              value: `${question.title}_${subq.title}_0.shown`,
            },
            {
              title: baseTitle,
              value: `${question.title}_${subq.title}_1.shown`,
            }
          )
        })
      } else if (question.type === 'R') {
        const answers = question.answers ?? []
        answers.forEach((_, index) => {
          result.push({
            title: `${question.title}: [RANK ${index + 1}] ${getQuestionTitle(question, language)}`,
            value: `${question.title}_${index + 1}.shown`,
          })
        })
      } else {
        if (Array.isArray(question.subquestions)) {
          question.subquestions.forEach((subq) => {
            result.push({
              title: `${question.title}: [${getQuestionTitle(subq, language)}] ${getQuestionTitle(question, language)}`,
              value: `${question.title}_${subq.title}.shown`,
            })

            if (question.type === 'P') {
              result.push({
                title: `${question.title}: [Comment] [${getQuestionTitle(subq, language)}] ${getQuestionTitle(question, language)}`,
                value: `${question.title}_${subq.title}comment.shown`,
              })
            }
          })
        } else {
          result.push({
            title: `${question.title}: ${getQuestionTitle(question, language)}`,
            value: `${question.title}.shown`,
          })

          if (question.type === 'O') {
            result.push({
              title: `${question.title}: [Comment] ${getQuestionTitle(question, language)}`,
              value: `${question.title}_comment.shown`,
            })
          }
        }
      }
    })
  })

  return result
}

const hasPreviousQuestions = (group, question, groupIndex, questionIndex) => {
  const isBeforeGroup = group.sortOrder - 1 < groupIndex
  const isBeforeQuestionInGroup = question.sortOrder - 1 < questionIndex
  const isBeforeOrSameGroup = group.sortOrder - 1 <= groupIndex

  return isBeforeGroup || (isBeforeQuestionInGroup && isBeforeOrSameGroup)
}

const getQuestionTitle = (question, language) => {
  return RemoveHTMLTagsInString(
    question.l10ns?.[language]?.question || ''
  ).trim()
}
